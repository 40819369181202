<template>
  <div>
    <h3>{{ this.isAddMode ? "Opret" : "Rediger" }} Hardware tilvalg</h3>

    <vx-card no-shadow class="mt-8">
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Navn</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="hardwareOption.navn"
            placeholder="Hardware tilvalgs navn fx: Trådløst tastatur ..."
            :disabled="!this.isAddMode"
          />
        </div>
      </div>
      
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Pris</span>
        </div>
        <div class="vx-col sm:w-2/5 w-full">
          <vs-input
            class="w-full"
            v-model="hardwareOption.pris"
            placeholder="Pris for enhed..."
          />
        </div>
      </div>

    </vx-card>
    
    <div class="mt-4">
      <backbutton></backbutton>
      <vs-button
        @click="saveData"
        id="save-button"
        class="ml-4 mr-auto mt-2"
      >
        {{ this.isAddMode ? "Opret" : "Opdater" }}
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      query: "*",
      hardwareOption: {
        navn: "",    
        pris: ""
      },

    };
  },
  computed: {
    isAddMode() {
      return !this.optionId;
    }
  },
  methods: {
    saveData: function() {
      this.$vs.loading();

      if (this.optionId) {
        this.updateHardwareOption(this.hardwareOption);
      } else {
        this.registerHardwareOption(this.hardwareOption);
      }
    },
    updateHardwareOption(payload) {
      this.$http
        .put(process.env.VUE_APP_API + "/HardwareOptions/"+ this.optionId, payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Hardware tilvalg opdateret"
            });

            this.$router.push({ name: "hardwareOptions" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Hardware tilvalg kunne ikke opdateres: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
    registerHardwareOption(payload) {
      this.$http
        .post(process.env.VUE_APP_API + "/HardwareOptions", payload)
        .then(resp => {
          if (resp.data.success === true) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Hardware tilvalg oprettet"
            });

            this.$router.push({ name: "hardwareOptions" }).catch(() => {});
          } else {
            console.log(resp.data.error);

            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Hardware tilvalg kunne ikke oprettes: " + resp.data.error
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {

    if (!this.isAddMode) {
      this.$vs.loading();

      this.$http
        .get(process.env.VUE_APP_API + "/HardwareOptions/" + this.optionId)
        .then(resp => {
          this.hardwareOption = resp.data;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    }
    else{
      var copyId = this.$route.query.copyFromId
      if (copyId){
        this.$vs.loading();

        this.$http
          .get(process.env.VUE_APP_API + "/HardwareOptions/" + copyId)
          .then(resp => {
            this.hardwareOption = resp.data;
            this.hardwareOption.optionId = ""
            this.hardwareOption.type = ""
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
select {
  height: 40px;
  padding: 10px;
  border-color: lightgrey;
  border-radius: 5px;
  color: #626262;
}
.horizontal-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.selector {
  width: 40% !important;
}
.selector select {
  width: 100% !important;
}
</style>
